import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, useMediaQuery } from '@material-ui/core';

import { GeneralHero } from '../components/General/GeneralHero';
import { Resources } from '../components/General/Resources';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWaveDefault } from '../components/Waves/SectionWaveDefault';
import { SEO } from '../components/SEO';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { SectionLight } from '../components/Sections/SectionLight';
import { SectionWhite } from '../components/Sections/SectionWhite';
import Form from '../components/ContactForm/Form';

import LocaleContext from '../context/LocaleContext';

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.white,
  },
  heroVideo: {
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
    },
  },
  formWaveDown: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-3rem',
    },
  },
  formWaveUp: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-10rem',
    },
  },
  formCont: {
    padding: '12rem 0 24rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '8rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5rem 0',
    },
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '32.95%',
    minHeight: '100%',
    minWidth: '59%',
    border: 'none',
    marginTop: '-16rem',
    zIndex: 10,
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: '2rem',
    border: '5px white solid',
    boxShadow:
      '0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('md')]: {
      marginTop: '-10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '45%',
      minHeight: '100%',
      minWidth: '80%',
      marginTop: '-6rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '56.25%',
      minHeight: '100%',
      minWidth: '100%',
      marginTop: '-3rem',
    },
  },
}));

const HeroVideo = ({
  url,
  playing = false,
  loop = false,
  muted = false,
  controls = true,
  width = '100%',
  height = '100%',
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent='center' alignItems='center'>
      <div className={classes.playerWrapper}>
        <ReactPlayer
          url={url}
          playing={playing}
          loop={loop}
          muted={muted}
          width={width}
          height={height}
          controls={controls}
          className={classes.reactPlayer}
        />
      </div>
    </Grid>
  );
};

export default function SoftwarePage({ data: { software }, location }) {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  //state for selected card for features
  const [selectedFeature, setSelectedFeature] = useState(null);

  const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
    FeatureThumbnailContext
  );

  //feature list modal controls
  const [featureModalOpen, setFeatureModalOpen] = useState(false);

  useEffect(() => {
    setSelectedFeature(software.featureSection[0]?._key);
  }, []);

  const {
    metaTitle,
    metaDescription,
    contactForm,
    pardotUrl,
    hero,
    resources,
    _rawResourceBody,
    resourceTitle,
    _rawByTheNumbersHeader,
    byTheNumbers,
    byTheNumbersDisclaimer,
    noStatsCta,
    relatedFeatures = [],
    relatedHeader = '',
  } = software;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  const md = useMediaQuery('(max-width: 960px)');

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <SectionBlue additionalTop={100} extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      {hero.heroVideo ? (
        <Container>
          <HeroVideo url={hero.heroVideo} />
        </Container>
      ) : null}
      <ProductFeatureBody
        body={software}
        setFeatureModalOpen={setFeatureModalOpen}
        stats={byTheNumbers}
        statsHeader={_rawByTheNumbersHeader}
        statDisclaimer={byTheNumbersDisclaimer}
        noStatsCta={noStatsCta}
        related={relatedFeatures}
        relatedHeader={relatedHeader}
      />

      <SectionLight top={md} bottom={md}>
        <Form
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          location={location}
        />
      </SectionLight>

      <SectionWhite top additionalTop={100} additionalBottom={100} zIndex={3}>
        <SectionWaveDefault
          top='0px'
          fillColorBorder='#F5F9FF'
          flipHorizontal={true}
        />
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>
      <ThumbnailFeatureModal
        open={modalOpen}
        setFeatureModalOpen={setModalOpen}
        wistiaLink={selectedThumbnail}
        feature
      />
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    software: sanitySoftware(slug: { current: { eq: $slug } }) {
      _id
      title
      metaTitle
      metaDescription
      slug {
        current
      }
      leadoo {
        botId
        seamless
      }
      hero {
        softwareLogo {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              fit: FILLMAX
              # width: 350
              height: 90
            )
          }
        }
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        showEmailForm
        heroVideo
      }
      _rawOverviewContent
      overviewImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      overviewCta
      overviewVideo
      _rawByTheNumbersHeader
      byTheNumbers {
        number
        unit
        leftAlignUnit
        _rawDescription
        icon
      }
      byTheNumbersDisclaimer
      noStatsCta {
        ctaBackground {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaHeader
        _rawCtaBlockHeader
        ctaLink
        ctaSubheader
        ctaText
        internalLink
      }
      featureHeader
      featureSection {
        _key
        title
        cardTitle
        cardFaIcon
        cardIconColor {
          hexValue
        }
        specialFaIcon {
          title
          name
          colors {
            color
          }
        }
        bodySubHeader
        bodyHeader
        image {
          featureImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        videoVariant
        androidLink
        iosLink
        qbLink
        # svgVariant
        thumbnailVideoUrl
        ctaText
        ctaLink
        _rawBodyCopy
        internalLink
      }
      testimonialHeader
      testimonials {
        title
        testimonialText
        nameAndTitle
        company
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        videoVariant
      }
      relatedHeader
      relatedFeatures {
        faIcon {
          name
          colors {
            color
          }
        }
        title
        slug {
          current
        }
      }
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
      }
    }
  }
`;
